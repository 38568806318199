import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  IconButton,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import { FaFacebook, FaInstagram, FaUser, FaSearch, FaAngleUp } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

import './Navbar.css';

import UserPopover from './UserPopover.js';

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const [ showSearchbar, setShowSearchBar ] = useState(false);

  return (
    <Box
        id="navbar"
        className="no-select"
        backgroundColor='#FFFDF2'
    >

      <Flex
        // bg={useColorModeValue('white', 'gray.800')}
        // color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2, md: 3 }}
        px={{ base: 4 }}
        // borderBottom={1}
        // borderStyle={'solid'}
        // borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >

        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>

          <IconButton
            // color='gray.100'
            role="group"
            onClick={onToggle}
            icon={isOpen ? 
                <CloseIcon
                    w={3}
                    h={3}
                    // _groupHover={{ color: 'gray.800' }}
                />
                :
                <HamburgerIcon
                    w={5}
                    h={5}
                    // _groupHover={{ color: 'gray.800' }}
                />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'flex-start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontSize="xl"
            fontFamily={'heading'}
            fontWeight={600}
            // color='gray.100'
            className="navbar-brand"
          >
            Sri Astik Roy
          </Text>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={3}
          fontSize="xl"
        >
            <UserPopover />

          {
            showSearchbar
            ?
            <Button
              variant="outline"
              onClick={() => setShowSearchBar(false)}
            //   color='gray.300'
              role="group"
            >
              <Icon as={FaAngleUp}
                // _groupHover={{ color: 'gray.800' }}
              />
            </Button>
            :
            <Button
              variant="outline"
              onClick={() => setShowSearchBar(true)}
            //   color='gray.300'
              role="group"
            >
              <Icon as={FaSearch}
                // _groupHover={{ color: 'gray.800' }}
              />
            </Button>
          }

        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>

      {
        showSearchbar &&
        <SearchBar />
      }
    </Box>
  )
}

const SearchBar = () => {

  const handleSearchButtonClick = () => {
    //
  }

  return (
    <div className="searchbar">

      <Text fontSize='xl' textAlign='center' mb='0.5em'>What do you want to find?</Text>

      <InputGroup className="input-group" w={{ sm: '70vw', md: '60vw' }}>
        <Input type="text" placeholder="🔍 Search here" />
        
        <InputRightElement>
          <Button variant="outline" onClick={handleSearchButtonClick} >
            <Icon as={FaSearch}  />
          </Button>
        </InputRightElement>
      </InputGroup>

    </div>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                as={Link}
                p={2}
                to={navItem.href ?? '#'}
                fontSize={'lg'}
                fontWeight={700}
                // color={linkColor}
                // color='gray.300'
                _hover={{
                  textDecoration: 'underline',
                  // color: linkHoverColor,
                //   color: 'gray.100'
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                // bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
                >
                <Stack>
                  {navItem.children.map((child, idx) => (
                    <DesktopSubNav key={idx} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Box
      as={Link}
      to={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
    //   color='gray.300'
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
      >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            // _groupHover={{ color: 'pink.400' }}
            _groupHover={{
              textDecoration: 'underline'
            }}
            // fontWeight={500}
            fontWeight={'bold'}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'gray.800'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

const MobileNav = () => {
  return (
    <Stack
      // bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
      fontSize={'lg'}
    >
      {NAV_ITEMS.map((navItem, idx) => (
        <MobileNavItem key={idx} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        // py={2}
        p={2}
        as={Link}
        to={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        rounded={'md'}
        _hover={{
        //   color: 'gray.800',
          textDecoration: 'underline',
          backgroundColor: 'pink.50'
        }}
        role="group"
      >
        <Text
          fontWeight={'bold'}
          // color={useColorModeValue('gray.600', 'gray.200')}
        //   color="gray.300"
        //   _groupHover={{ color: 'gray.800' }} // CODE
        >
          {label}

          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Text>
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          // mt={2}
          mt={0}
          mb={3}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child, idx) => (
              <Box
                as={Link}
                key={idx}
                // py={2}
                p={2}
                to={child.href}
                w="100%"
                rounded={'md'}
                fontWeight={'bold'}
                _hover={{
                  textDecoration: 'underline',
                //   color: 'gray.800',
                  backgroundColor: 'pink.50'
                }}
              >
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

const NAV_ITEMS = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'About me!',
    children: [
      {
        label: 'Intro',
        href: '/about/intro',
      },
      {
        label: 'Education',
        href: '/about/education',
      },
      {
        label: 'Experience',
        href: '/about/experience',
      },
      {
        label: 'Projects',
        href: '/about/projects',
      },
      {
        label: 'Achievements',
        href: '/about/achievements',
      },
      {
        label: 'Hobbies',
        href: '/about/hobbies',
      }
    ],
  },
  {
    label: 'Courses',
    href: '/courses',
  },
  {
    label: 'My Blogs',
    href: '/blogs',
  },
  {
    label: 'Contact',
    href: '/contact',
  }
];