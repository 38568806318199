import {
    Box,
    Flex,
    Text,
    UnorderedList,
    ListItem
} from '@chakra-ui/react';

export default function NotFound() {
    return (
        <Flex
            justify={'center'}
            // border='1px solid black'
            className='no-select'
        >
            <Flex
                direction='column'
            >
                <Text
                    mt='1em'
                    fontSize='3xl'
                >
                    Oh fuck! I cannot find what you are looking for 😐
                </Text>

                <UnorderedList
                    styleType='square'
                    my='1em'
                >
                    {
                        POSSIBLE_ISSUES.map((el, idx) =>
                            <ListItem key={idx}>
                                <Text fontSize='xl'>
                                    {el}.
                                </Text>
                            </ListItem>
                        )
                    }
                </UnorderedList>

                Report other issues here.
            </Flex>
        </Flex>
    );
}

const POSSIBLE_ISSUES = [
    'There is no such content I can provide here',
    'Maybe you have misspelled  the URL',
    'This content is under development',
];