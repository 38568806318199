import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Text
} from '@chakra-ui/react';

import { AuthContext } from '../../Contexts.js';

export default function LogOut() {

    const AuthContextData = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        AuthContextData.setAuth({
            isAuth: false,
            role: ''
        });
        navigate('/login');
    }, []);

    return (
        <Box py={'3em'} className='no-select'>
            <Text fontSize='xl' align='center'>Logging out...</Text>
        </Box>
    );
}