import { useState } from 'react';
import { LoremIpsum, Avatar } from 'react-lorem-ipsum';

import {
    Box,
    Flex,
    Stack,
    Text,
    Heading,
    Image,
    Button,
    Card,
    CardBody
} from '@chakra-ui/react';

function ShortDetails() {
    return (
        <Card
            w={{ base: '100%', md: '30%' }}
            mb={{ base: '1em', md: '0' }}
            h={'100%'}
            backgroundColor='#FFFDF2'
            _hover={{ backgroundColor: 'white', border: '1px solid pink' }}
        >
            <CardBody>
                <Image
                    src={`/assets/img/problem_solving.jpg`}
                    alt='Green double couch with wooden legs'
                    borderRadius='lg'
                    width='100%'
                    height='14em'
                />

                <Stack
                    mt='1em'
                >
                    <Heading fontSize='2xl'>Road to Specialist</Heading>

                    <Text fontSize='lg'>Become a specialist with diving deep into Data Structures and Algorithms.</Text>

                    <Text
                        color='blue'
                        opacity='80%'
                        fontSize='xl'
                    >
                        TK. 3999
                    </Text>

                </Stack>

                <Button
                    mt={'1em'}
                    colorScheme='teal'
                    w='100%'
                >
                    Enroll now!
                </Button>

            </CardBody>
        </Card>
    );
}

function LongDetails() {
    return (
        <Card
            w={{ base: '100%', md: '65%' }}
            backgroundColor='#FFFDF2'
            _hover={{ backgroundColor: 'white', border: '1px solid pink' }}
        >
            <CardBody>
                <LoremIpsum p={'4'} />
            </CardBody>
        </Card>
    );
}

export default function SingleCourse() {
    return (
        <Box
            p={'1em'}
            className='no-select'>

            <Text
                fontSize='2xl'
                fontWeight={'bold'}
                opacity={'50%'}
            >
                Course: Road to Specialist
            </Text>
            <hr />

            <Flex
                mt='1em'
                justify={'space-evenly'}
                direction={{ base: 'column', md: 'row' }}
            >
                <ShortDetails />
                <LongDetails />
            </Flex>

        </Box>
    );
}