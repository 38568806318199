import { useState, useRef } from 'react';

import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Input,
    Button,
    Card,
    CardBody,
    Avatar,
    AvatarBadge
} from '@chakra-ui/react';

function ChangeAvatar() {

    const [ currentAvatar, setCurrentAvatar ] = useState('avatar2.jpg')
    const [ selectedAvatar, setSelectedAvatar ] = useState(currentAvatar);

    return (
        <Box>
            <Heading 
                fontSize='lg'
                opacity={'80%'}
            >
                Change avatar
            </Heading>

            <Stack direction='horizontal' my='1.5em'>
                {
                    AVATAR_SRC.map((src, idx) =>
                        <Avatar
                            src={`/assets/img/${src}`}
                            outline={src === selectedAvatar ? '4px dotted teal' : 'none'}
                            key={idx}
                            size='lg'
                            name='?'
                            cursor='pointer'
                            onClick={() => setSelectedAvatar(src)}
                        >
                            {
                                src === currentAvatar && 
                                <AvatarBadge
                                    // borderColor='papayawhip'
                                    bg='teal'
                                    boxSize='1em'
                                />
                            }
                        </Avatar>
                    )
                }
            </Stack>

            <Button colorScheme='teal' isDisabled={selectedAvatar === currentAvatar}>
                Change
            </Button>
        </Box>
    );
}

function ChangePassword() {

    const [ disableButton, setDisableButton ] = useState(true);

    const currentPasswordRef = useRef();
    const newPasswordRef = useRef();
    const retypeNewPasswordRef = useRef();

    function handleChangePassword() {
        console.log('Password changed!');
    }

    function handleInputChange() {
        if(currentPasswordRef.current.value && newPasswordRef.current.value && retypeNewPasswordRef.current.value)
            setDisableButton(false);
        else
            setDisableButton(true);
    }

    return(
        <Box>
            <Heading 
                fontSize='md'
                opacity={'80%'}
            >
                Change password
            </Heading>

            <Stack spacing={'1em'} my={'1.5em'}>
                <Input ref={currentPasswordRef} onChange={handleInputChange} variant='flushed' type='password' placeholder='Current password' />
                <Input ref={newPasswordRef} onChange={handleInputChange} variant='flushed' type='password' placeholder='New password' />
                <Input ref={retypeNewPasswordRef} onChange={handleInputChange} variant='flushed' type='password' placeholder='Retype new password' />
            </Stack>

            <Button
                colorScheme='teal'
                onClick={handleChangePassword}
                isDisabled={disableButton}
            >
                Change
            </Button>
        </Box>
    );
}

export default function Settings() {
    return (
        <Box
            p='1em'
            className='no-select'
        >
            <Text
                fontSize='2xl'
                fontWeight={'bold'}
                opacity={'50%'}
            >
                Settings
            </Text>
            <hr />

            <Flex
                justify='center'
            >
                <Card
                    w={{ base: '100%', lg: '60%' }}
                    mt='1em'
                    backgroundColor='inherit'
                >

                    <CardBody>
                        <Stack spacing='2em'>
                            
                            <ChangeAvatar />
                            <ChangePassword />

                        </Stack>
                    </CardBody>

                </Card>
            </Flex>

        </Box>
    );
}

const AVATAR_SRC = [
    'avatar1.avif',
    'avatar2.jpg',
    'avatar3.avif',
    'avatar44.avif',
    'avatar4.avif'
]