import { Routes, Route } from 'react-router-dom';

import {
    Box,
    Text
} from '@chakra-ui/react';

import Home from './../../../routes/Home/Home.js';
import About from './../../../routes/About/About.js';
import Courses from './../../../routes/Courses/Courses.js';
import Blogs from './../../../routes/Blogs/Blogs.js';
import Contact from './../../../routes/Contact/Contact.js';

import LogIn from './../../../routes/LogIn/LogIn.js';
import SignUp from './../../../routes/SignUp/SignUp.js';

import Notifications from './../../../routes/Notifications/Notifications.js';
import Profile from './../../../routes/Profile/Profile.js';
import Payment from './../../../routes/Payment/Payment.js';
import Transactions from './../../../routes/Transactions/Transactions.js';
import Settings from './../../../routes/Settings/Settings.js';
import LogOut from './../../../routes/LogOut/LogOut.js';

import NotFound from './../../../routes/NotFound/NotFound.js';

export default function MainContent() {
    return (
        <Box
            minH={'88vh'}
            backgroundColor='#FFFDF2'
            id="main-content"
        >
             <Routes>
                <Route path="/" element={ <Home/> } />                
                <Route path="/about" element={ <About /> } />
                <Route path="/courses/*" element={ <Courses /> } />

                <Route path="/blogs" element={ <Blogs /> } />
                <Route path="/contact" element={ <Contact /> } />

                <Route path="/login" element={ <LogIn /> } />
                <Route path="/signup" element={ <SignUp /> } />

                <Route path="/notifications/*" element={ <Notifications /> } />
                <Route path="/profile" element={ <Profile /> } />
                <Route path="/payment" element={ <Payment /> } />
                <Route path="/transactions" element={ <Transactions /> } />
                <Route path="/settings" element={ <Settings /> } />
                <Route path="/logout" element={ <LogOut /> } />

                <Route path="/*" element={ <NotFound /> } />
            </Routes>

        </Box>
    );
}