import {
    Flex,
    Text,
    Image,
    SlideFade,
    ScaleFade
} from '@chakra-ui/react';

const avatar_src = '/assets/img/admin_avatar.svg';

export default function Banner() {
    return (
        <Flex
            justify={'center'}
            py={'6em'}
            px={'1em'}
            backgroundColor='#FFFDF2'
        >
            <Flex
                direction={'column'}
            >
                <SlideFade in={true}>
                <Text
                    align='center'
                    fontSize='4xl'
                    fontWeight='bold'
                >
                    Software Programmer & Full Stack Web Developer
                </Text>
                </SlideFade>

                <SlideFade offsetY='20px' in={true}>
                <Text
                    align='center'
                    fontSize='2xl'
                >
                    I turn innovative ideas into lines of codes and this is what I love to do.
                </Text>
                </SlideFade>

                <ScaleFade
                    in={true}
                    initialScale={0.5}
                >
                <Image
                    src={avatar_src}
                    width="13em"
                    height="13em"
                    m="3em auto"
                    mb='1em'
                />
                
                <Text fontSize='xl' align='center'>DSA | MERN | Linux</Text>
                </ScaleFade>

            </Flex>
        </Flex>
    );
}