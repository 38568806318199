import {
    Box,
    Text
} from '@chakra-ui/react';

export default function Blogs() {
    return (
        <Box>
            I am fucking blogs :D
        </Box>
    );
}