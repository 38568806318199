import { Routes, Route } from 'react-router-dom';

import AllNotifications from './AllNotifications.js';
import SingleNotification from './SingleNotification.js';

import NotFound from '../NotFound/NotFound.js';

export default function Notifications() {
    return (
        <Routes>
            <Route index element={<AllNotifications />} />
            <Route path=':id' element={<SingleNotification />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}