import { useContext } from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Image,
    Icon,
    Card,
    CardHeader,
    CardBody
} from '@chakra-ui/react';

import { IoNotifications } from "react-icons/io5";

import { AuthContext } from '../../Contexts.js';


const avatar_src = '/assets/img/user_avatar.jpg';

function EnrolledCourse({ course }) {
    return (
        <Card
            backgroundColor='inherit'
            _hover={{ backgroundColor: 'white' }}
        >
            <CardBody>
                <Heading size='md' mb={'0.3em'}>{course.title}</Heading>
                <Box
                    fontSize='lg'
                >
                    <i>Enrolled at:</i> {new Date(course.enrolledAt).toLocaleDateString()} <br />
                    <i>Payment:</i> {(course.payment * 100 / course.price).toFixed(2)}%
                    <Link
                        to={`/payment?course=${course.title}`}
                        style={{
                            marginLeft: '0.5em',
                            color: 'blue'
                        }}
                    >
                        <Text display='inline' _hover={{ textDecoration: 'underline' }}>Pay now</Text>
                    </Link>
                </Box>
            </CardBody>
        </Card>
    );
}

export default function Profile() {

    const AuthContextData = useContext(AuthContext);

    return (
        <Card
            className='no-select'
            backgroundColor='#FFFDF2'
        >
            <CardHeader>
                <Text
                    fontSize='2xl'
                    fontStyle={'italic'}
                >
                    👋 Hello mylovelyuser!
                </Text>
            </CardHeader>

            <CardBody>

                    <Flex
                        direction='column'
                    >

                        <Image
                            src={avatar_src}
                            width={'10em'}
                            borderRadius='50%'
                            m='0 auto'
                        />

                        <Text
                            my={'1em'}
                            align='center'
                            fontSize='lg'
                            opacity={'80%'}
                        >
                            {
                                AuthContextData.Auth.role === 'a'
                                ?
                                'Admin'
                                :
                                'Student'
                            }
                        </Text>
                        
                        <Link to='/notifications'>
                            <Text
                                align='center'
                                fontWeight='bold'
                                fontSize='lg'
                                color='darkslategray'
                                _hover={{ textDecoration: 'underline' }}
                            >
                                You have +2 new notifications! <Icon as={IoNotifications} />
                            </Text>
                        </Link>

                    </Flex>

                    <Card
                        mt={'1em'}
                        // border='1px solid pink'
                        backgroundColor='inherit'
                    >
                        <CardHeader
                            // border={'1px solid black'}
                        >
                            <Text
                                fontSize='xl'
                                fontWeight={'bold'}
                                opacity={'70%'}
                            >
                                Enrolled Courses
                            </Text>
                        </CardHeader>

                        <CardBody
                            pt='0'
                        >
                            <Stack spacing='1em'>
                            {
                                ENROLLED_COURSES.map((course, idx) =>
                                    <EnrolledCourse key={idx} course={course} />
                                )
                            }
                            </Stack>
                        </CardBody>
                    </Card>

            </CardBody>
        </Card>
    );
}

const ENROLLED_COURSES = [
    {
        title: 'Road to Specialist',
        enrolledAt: Date.now(),
        price: 3999,
        payment: 2000
    },
    {
        title: 'Full Stack Web Development',
        enrolledAt: Date.now(),
        price: 5999,
        payment: 3000
    }
];