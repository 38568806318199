import { Link } from 'react-router-dom';

import {
    Box,
    Flex,
    Stack,
    Text,
    Icon,
    Card,
    CardHeader,
    CardBody,
    Input,
    Checkbox,
    Button
} from '@chakra-ui/react';

import { FaUserShield } from "react-icons/fa";
import { IoRocket } from "react-icons/io5";

export default function SignUp() {
    return (
        <Box
            className='no-select'
        >            
            <Flex
                direction={'column'}
                my={'1em'}
            >
                <Flex
                    justify={'center'}
                >

                    <Card w={{ base: '100%', sm: '80%', md: '60%', lg: '40%' }}>

                        <CardHeader>
                            <Text fontSize='2xl' align='center'>
                                <Icon as={FaUserShield} /> Create an account
                            </Text>
                        </CardHeader>

                        <CardBody>

                            <Stack spacing={'1em'}>

                                <Input variant='flushed' placeholder='Username' />
                                <Input variant='flushed' type='password' placeholder='Password' />
                                <Input variant='flushed' type='password' placeholder='Retype password' />

                                <Checkbox>I accept all the terms and conditions</Checkbox>

                                <Button
                                    w='50%'
                                    m='0 auto'
                                    mt={'0.5em'}
                                    colorScheme='teal'
                                >
                                    Create account &nbsp; <Icon as={IoRocket} />
                                </Button>
                            </Stack>

                            <Text mt={'1.5em'}>
                                Already have an account? <Link to='/login'><u>Log in.</u></Link>
                            </Text>

                        </CardBody>
                    </Card>

                </Flex>

                {/* <Flex justify={'center'}>
                    <Text>Issues here</Text>
                </Flex> */}

            </Flex>            

        </Box>
    );
}