import { Routes, Route } from 'react-router-dom';

import AllCourses from './AllCourses.js';
import SingleCourse from './SingleCourse.js';

import NotFound from '../NotFound/NotFound.js';

export default function Courses() {
    return (
        <Routes>
            <Route index element={<AllCourses />} />
            <Route path=':id' element={<SingleCourse />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}