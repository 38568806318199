import { Link } from 'react-router-dom';

import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Button,
    Icon,
    Card,
    CardBody,
    Avatar
} from '@chakra-ui/react';

import { IoMdMailOpen } from "react-icons/io";
import { MdMarkunread } from "react-icons/md";

function Notification({ notification }) {
    return (
        <Link to={notification.id}>
        <Box
            // border={'1px solid pink'}
            p='0.5em'
            borderRadius='md'
            _hover={{ boxShadow: 'md' }}
            backgroundColor={notification.read ? 'inherit' : '#FFFDD0' }
        >
            <Flex
                justifyContent='space-between'
            >
                <Flex>
                    <Avatar name={notification.from} />

                    <Stack ml='1em'>
                        <Text fontSize='lg' fontWeight='bold' color='darkslategray'>{notification.title}</Text>

                        <Text>
                            <i>Time:</i> {new Date(notification.time).toLocaleString()}
                        </Text>
                    </Stack>
                </Flex>


                <Flex
                    direction='column'
                    justifyContent={'center'}
                    ml='0.5em'
                >
                    <Button variant='ghost'>
                        <Icon as={notification.read ? MdMarkunread : IoMdMailOpen} />
                    </Button>
                </Flex>

            </Flex>
        </Box>
        </Link>
    );
}

export default function AllNotifications() {
    return (
        <Box
            p='1em'
            className='no-select'
        >
            <Text
                fontSize='2xl'
                fontWeight={'bold'}
                opacity={'50%'}
            >
                Notifications
            </Text>
            <hr />

            <Flex
                justify='center'
            >
                <Card
                    w={{ base: '100%', lg: '60%' }}
                    mt='1em'
                    backgroundColor='inherit'
                >

                    <CardBody>
                        <Stack>

                            {
                                NOTIFICATIONS.map((notification, idx) =>
                                    <Notification key={idx} notification={notification} />
                                )
                            }

                        </Stack>
                    </CardBody>

                </Card>
            </Flex>

        </Box>
    );
}

const NOTIFICATIONS = [
    {
        id: '1234',
        title: 'Your payment request has been verified',
        message: 'Thanks for paying! Your payment request has been successfully verified. See the details at transactions page.',
        from: 'Admin',
        time: Date.now(),
        read: false
    },
    {
        id: '1234',
        title: 'Your payment request has been submitted',
        message: 'Your payment request will be verified very soon. Till then sit back and keep patience.',
        from: 'System',
        time: Date.now(),
        read: false,
    },
    {
        id: '1234',
        title: 'Pay at least 25% to start the class',
        message: 'To start the class, you must pay at least 25% of the total price to start the classes. So what are you waiting for? Pay and jump start your course!',
        from: 'System',
        time: Date.now(),
        read: true,
    },
    {
        id: '1234',
        title: 'Pay at least 25% to start the class',
        message: 'To start the class, you must pay at least 25% of the total price to start the classes. So what are you waiting for? Pay and jump start your course!',
        from: 'System',
        time: Date.now(),
        read: true,
    },
    {
        title: 'Thanks for joining me!',
        message: 'Thanks for joining me! Hoping to collaborate with you very soon.',
        from: 'Admin',
        time: Date.now(),
        read: true
    }
];