import { useNavigate } from 'react-router-dom';

import {
    Box,
    Flex,
    Stack,
    Text,
    Image,
    Input,
    Select,
    Checkbox,
    Button,
    Icon,
    Card,
    CardHeader,
    CardBody,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure
} from '@chakra-ui/react';

import { FaUserShield, FaCashRegister} from "react-icons/fa";
import { IoRocket } from "react-icons/io5";

const bkash_logo_src = 'bkash_logo.png';
const bkash_qr_src = 'bkash_qr.jpg';

function HowToPay() {
    
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Text
                onClick={onOpen}
                fontSize='lg'
                color='blue'
                opacity={'80%'}
                cursor='pointer'
                _hover={{ textDecoration: 'underline' }}
            >
                How to pay? 😡
            </Text>
        
            <Modal size='lg' isOpen={isOpen} onClose={onClose}  scrollBehavior='inside'>
                <ModalOverlay />
                <ModalContent>

                <ModalHeader  className='no-select'>
                    <Image
                        src={`/assets/img/${bkash_logo_src}`}
                        w='1.3em'
                        display='inline'
                        mr='0.5em'
                    />
                    Pay with bKash
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody  className='no-select'>
                    
                    <Text
                        fontSize='lg'
                        textAlign={'justify'}
                    >
                        Scan the QR Code below with your bKash App and proceed to pay. Also don't forget to copy the Transaction ID after payment, which can be found after payment through bKash App or SMS.
                    </Text>

                    <Image
                        src={`/assets/img/${bkash_qr_src}`}
                        h={'20em'}
                        m='0 auto'
                    />
                    
                </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default function Payment() {

    const navigate = useNavigate();

    function handlePaymentSubmit() {
        navigate('/transactions');
    }

    return (
        <Box
            p={'1em'}
            className='no-select'
        >
            <Flex
                justify={'center'}
            >
                <Card w={{ base: '100%', sm: '80%', md: '60%', lg: '40%' }}>

                    <CardHeader>
                        <Text fontSize='2xl' align='center'>
                            <Icon as={FaCashRegister} /> Payment
                        </Text>
                    </CardHeader>

                    <CardBody>

                        <Stack spacing={'1em'}>

                            <Select variant='flushed' placeholder='Select course'>
                                <option value='option1'>Road to specialist</option>
                                <option value='option2'>Full Stack Web Development</option>
                                <option value='option3'>Linux Fundamentals</option>
                            </Select>

                            <Select variant='flushed' placeholder='Payment method'>
                                <option value='option1'>bKash</option>
                            </Select>

                            <Input variant='flushed' type='text' placeholder='Transaction ID' />

                            <Checkbox>I have revised the form</Checkbox>

                            <Button
                                w='50%'
                                m='0 auto'
                                mt={'0.5em'}
                                colorScheme='teal'
                                onClick={handlePaymentSubmit}
                            >
                                Submit &nbsp; <Icon as={IoRocket} />
                            </Button>

                        </Stack>

                        <Box mt={'1.5em'}>
                            <HowToPay />
                        </Box>

                    </CardBody>
                </Card>
            </Flex>

        </Box>
    );
}