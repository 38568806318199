import {
    Box,
    Flex,
    Stack,
    Text,
    Card,
    CardBody,
} from '@chakra-ui/react';

function Transaction({ transaction }) {
    return (
        <Box
            // border={'1px solid pink'}
            p='0.5em'
            borderRadius='md'
            _hover={{ boxShadow: 'md' }}
            backgroundColor={transaction.verified ? 'inherit' : '#FFFDD0' }
        >

            <Stack>
                <Text fontSize='lg' fontWeight='bold' color='darkslategray'>{transaction.title}</Text>

                <Text>
                    <i>Method:</i> {transaction.method} <br />
                    <i>Transaction ID:</i> {transaction.transactionID} <br />
                    <i>Time:</i> {new Date(transaction.time).toLocaleString()} <br />
                    <i>Status:</i> {transaction.verified ? 'Verified' : 'Pending' } <br />
                    <i>Amount:</i> {transaction.verified ? transaction.amount : 'N/A' }
                </Text>
            </Stack>

        </Box>
    );
}

export default function Transactions() {
    return (
        <Box
            p='1em'
            className='no-select'
        >
            <Text
                fontSize='2xl'
                fontWeight={'bold'}
                opacity={'50%'}
            >
                Transactions
            </Text>
            <hr />

            <Flex
                justify='center'
            >
                <Card
                    w={{ base: '100%', lg: '60%' }}
                    mt='1em'
                    backgroundColor='inherit'
                >

                    <CardBody>
                        <Stack>

                            {
                                TRANSACTIONS.map((transaction, idx) =>
                                    <Transaction key={idx} transaction={transaction} />
                                )
                            }

                        </Stack>
                    </CardBody>

                </Card>
            </Flex>

        </Box>
    );
}

const TRANSACTIONS = [
    {
        id: '1234',
        title: 'Road to Specialist',
        method: 'bKash',
        transactionID: 'QNEO3N20NZ',
        time: Date.now(),
        verified: false
    },
    {
        id: '1234',
        title: 'Road to Specialist',
        method: 'bKash',
        transactionID: 'QNEO3N20NZ',
        time: Date.now(),
        verified: true,
        amount: 1200
    },
    {
        id: '1234',
        title: 'Road to Specialist',
        method: 'bKash',
        transactionID: 'QNEO3N20NZ',
        time: Date.now(),
        verified: true,
        amount: 700
    }
];