import { useState } from 'react';

import './App.css';

import Navbar from './components/primary/Navbar/Navbar.js';
import MainContent from './components/primary/MainContent/MainContent.js';
import Footer from './components/primary/Footer/Footer.js';

import { AuthContext } from './Contexts.js';

function App() {

  const [ Auth, setAuth ] = useState({
    isAuth: false,
    role: ''
  });

  return (
    <div className="App">

      <AuthContext.Provider value={{ Auth, setAuth }}>

        <Navbar />
        <MainContent />
        <Footer />

      </AuthContext.Provider>

    </div>
  );
}

export default App;
