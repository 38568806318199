import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Flex,
    Stack,
    Text,
    Button,
    Icon,
    Card,
    CardHeader,
    CardBody,
    Avatar,
} from '@chakra-ui/react';

import { BellIcon } from '@chakra-ui/icons';
import { MdMarkunread } from "react-icons/md";

export default function SingleNotification() {

    const [ notification, setNotification ] = useState({
        id: '1234',
        title: 'Your payment request has been verified',
        message: 'Thanks for paying! Your payment request has been successfully verified. See the details at transactions page.',
        from: 'Admin',
        time: Date.now(),
        read: false
    });

    return (
        <Box
            p='1em'
        >

            <Flex
                justify='center'
            >
                <Flex
                    direction='column'
                    w={{ base: '100%', lg: '60%' }}
                >

                <Card
                    // w={{ base: '100%', lg: '60%' }}
                    backgroundColor='white'
                    // _hover={{ backgroundColor: 'white' }}
                >
                    <CardHeader
                        borderBottom={'1px dotted pink'}
                    >
                        
                        <Flex
                            justifyContent='space-between'
                            className='no-select'
                        >
                            <Flex>
                                <Avatar name={notification.from} />

                                <Stack ml='1em'>
                                    <Text fontSize='xl' fontWeight='bold' color='darkslategray'>{notification.title}</Text>

                                    <Text fontSize='lg'>
                                        <i>Time:</i> {new Date(notification.time).toLocaleString()}
                                    </Text>
                                </Stack>
                            </Flex>


                            <Flex
                                direction='column'
                                justifyContent={'center'}
                                ml='0.5em'
                            >
                                <Button variant='ghost'>
                                    <Icon as={MdMarkunread} />
                                </Button>
                            </Flex>

                        </Flex>
                    </CardHeader>

                    <CardBody>
                        <Text
                            fontSize='lg'
                        >
                            {notification.message}
                        </Text>

                    </CardBody>
                </Card>

                <Flex>
                    <Link to='/notifications'>
                        <Button mt='1em'>
                            <BellIcon mr='0.3em' /> See all notifications
                        </Button>
                    </Link>
                </Flex>

                </Flex>
            </Flex>
        </Box>
    );
}