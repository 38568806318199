import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    Box,
    Flex,
    Stack,
    Text,
    Icon,
    Card,
    CardHeader,
    CardBody,
    Input,
    Checkbox,
    Button
} from '@chakra-ui/react';

import { FaUserShield } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";

import { AuthContext } from './../../Contexts.js';

export default function LogIn() {

    const AuthContextData = useContext(AuthContext);
    const navigate = useNavigate();

    function handleLogIn() {
        AuthContextData.setAuth({
            isAuth: true,
            role: ''
        });
        navigate('/profile');
    }

    return (
        <Box
            className='no-select'
        >            
            <Flex
                direction={'column'}
                py={'1em'}
            >
                <Flex
                    justify={'center'}
                >

                    <Card w={{ base: '100%', sm: '80%', md: '60%', lg: '40%' }}>

                        <CardHeader>
                            <Text fontSize='2xl' align='center'>
                                <Icon as={FaUserShield} /> Log in
                            </Text>
                        </CardHeader>

                        <CardBody>

                            <Stack spacing={'1em'}>
                                <Input variant='flushed' placeholder='Username' />
                                <Input variant='flushed' type='password' placeholder='Password' />

                                <Checkbox>Stay signed in</Checkbox>

                                <Button
                                    w='50%'
                                    m='0 auto'
                                    mt={'0.5em'}
                                    colorScheme='teal'
                                    onClick={handleLogIn}
                                >
                                    Log in &nbsp; <Icon as={FiLogIn} />
                                </Button>
                            </Stack>

                            <Text mt={'1.5em'}>
                                Don't have an account? <Link to='/signup'><u>Create one!</u></Link>
                            </Text>

                        </CardBody>
                    </Card>

                </Flex>

                {/* <Flex justify={'center'}>
                    <Text>Issues here</Text>
                </Flex> */}

            </Flex>            

        </Box>
    );
}