import { useRef } from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Stack,
    Text,
    Heading,
    Image,
    Button,
    Flex,
    Card,
    CardBody,
    CardFooter,
    ScaleFade
} from '@chakra-ui/react';

import { useInView } from 'framer-motion';

const mock_img_src = 'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80';

function Course({ course }) {

    const courseRef = useRef(null);
    const isInView = useInView(courseRef);

    return (
        <ScaleFade
            in={isInView}
            initialScale={0.5}
        >
        <Card
            ref={courseRef}
            w={'sm'}
            m={ '1em' }
            backgroundColor='#FFFDF2'
            _hover={{ backgroundColor: 'white', border: '1px solid pink' }}
        >
            <CardBody>
                
                <Link to='gua'>
                    <Image
                        src={`/assets/img/${course.image}`}
                        alt='Green double couch with wooden legs'
                        borderRadius='lg'
                        width='100%'
                        height='14em'
                    />
                </Link>

                <Link to='gua'>
                    <Stack
                        mt='1em'
                    >
                        <Heading fontSize='2xl'>{course.title}</Heading>

                        <Text fontSize='lg'>{course.description}</Text>

                        <Text
                            color='blue'
                            opacity='80%'
                            fontSize='xl'
                        >
                            TK. {course.price}
                        </Text>

                    </Stack>
                </Link>

                <Link to='gua/enroll'>
                    <Button
                        mt={'1em'}
                        colorScheme='teal'
                        w='100%'
                    >
                        Enroll now!
                    </Button>
                </Link>

            </CardBody>

        </Card>
        </ScaleFade>
    );
}

export default function AllCourses() {
    return (
        <Box
            p={'1em'}
            className='no-select'
        >
            <Text
                fontSize='2xl'
                fontWeight={'bold'}
                opacity={'50%'}
            >
                Courses
            </Text>
            <hr />

            <Flex
                justify={'center'}
                wrap={'wrap'}
            >
                {
                    COURSES.map((course, idx) => <Course key={idx} course={course} />)
                }
                
            </Flex>
        </Box>
    );
}

const COURSES = [
    {
        title: 'Road to Specialist',
        description: 'Become a specialist with diving deep into Data Structures and Algorithms.',
        image: 'problem_solving.jpg',
        price: 3999,
        href: 'hereyougo'
    },
    {
        title: 'Full Stack Web Development',
        description: 'Become a full stack web developer with being prepared for a job!',
        image: 'mern.jpg',
        price: 5999,
        href: 'hereyougo'
    },
    {
        title: 'Linux Fundamentals',
        description: 'Learn to use Linux ensuring a striking skill in your resume.',
        image: 'linux.png',
        price: 1999,
        href: 'hereyougo'
    }
]