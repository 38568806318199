import {
    Box
} from '@chakra-ui/react';

import './Home.css';

import Banner from './Banner.js';

export default function Home() {
    return (
        <Box id="home" className='no-select'>
            <Banner />
            {/* I am home! */}
        </Box>
    );
}